@charset "UTF-8";
html {
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  box-sizing: border-box;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: "Open Sans";
  background-color: #e0e7e9;
  color: #171717;
  font-size: 1.4rem;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none !important;
}
*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}

.no-data {
  padding: 1.6rem;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #ffffff;
  color: #6a6a6a;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-animation: fade-in 0.5s linear;
  -moz-animation: fade-in 0.5s linear;
  -ms-animation: fade-in 0.5s linear;
  -o-animation: fade-in 0.5s linear;
  animation: fade-in 0.5s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(25%);
    -webkit-transform: translateY(25%);
    -moz-transform: translateY(25%);
    -ms-transform: translateY(25%);
    -o-transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
a {
  text-decoration: unset;
  color: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

.global-under-development {
  width: 100%;
  background-color: #e0e7e9;
  opacity: 0.5;
  padding: 2.4rem;
  text-align: center;
  color: #171717;
}

.global__view {
  padding: 2.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.global__error-wrapper {
  padding: 0 1.2rem 0.8rem 1.2rem;
  margin-top: -0.8rem;
}
.global__error {
  color: #fb3350;
  padding-top: 0.4rem;
  font-size: 10px;
  text-align: right;
}
.global__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 1.2rem auto;
}
.global__controls .om-btn {
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}
.global__header-title-text {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  color: #6a6a6a;
}
.global__list {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  -webkit-transition: margin 0.3s linear;
  -moz-transition: margin 0.3s linear;
  -ms-transition: margin 0.3s linear;
  -o-transition: margin 0.3s linear;
  transition: margin 0.3s linear;
}
.global__header {
  display: block;
  padding: 2.4rem 2.4rem 1.2rem 2.4rem;
  width: 100%;
  color: #777777;
  border-bottom: 1px solid #d6d6d6;
  background-color: #ffffff;
}
.global__header--with-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.global__header--with-content .titles {
  display: block;
}
.global__header--with-content .content {
  padding: 0.4rem 0 0.4rem 0.8rem;
}
@media screen and (max-width: 500px) {
  .global__header--with-content {
    display: block;
  }
  .global__header--with-content .titles {
    width: 100%;
  }
}
.global__header--title {
  font-size: 24px;
}
.global__header--title--param {
  font-size: 16px;
}
.global__header--category {
  font-size: 12px;
  font-weight: 600;
}
.global__header--description {
  font-size: 12px;
  font-weight: 100;
}
.global__list-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 0.8rem;
  justify-content: space-between;
  padding: 1.2rem 1.2rem;
  -webkit-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.2);
}
.global__list-item--name {
  font-size: 1.4rem;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1.2rem;
}
.global__list-item--controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.global__list-item--controls--icon {
  margin-left: 0.8rem;
  cursor: pointer;
  display: flex;
  max-width: 16px;
  -webkit-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -ms-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.global__list-item--controls--icon svg {
  pointer-events: none;
}
.global__list-item--controls--icon:hover {
  color: #a93e73;
}
.global__list-item--sorting {
  height: 2.7rem !important;
  margin-top: -1.2rem;
  margin-bottom: -1.2rem;
  margin-left: 1.2rem;
  text-align: center;
  width: 4rem !important;
  padding: 0 !important;
}

.floating-error-backdrop .floating-error-container {
  display: grid;
  padding: 0;
  grid-template-columns: 100%;
}
.floating-error-backdrop .floating-error-container .floating-error__title {
  grid-area: 1/1/2/2;
  position: relative;
  font-size: 2rem;
  font-weight: 500;
  height: 4.1rem;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.4rem 4.8rem 0.4rem 1.2rem;
}
.floating-error-backdrop .floating-error-container .floating-error__title::after {
  background-color: #e0e7e9;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  display: flex;
  width: 4.1rem;
  height: 4.1rem;
  color: #6a6a6a;
  content: "×";
  right: 0;
  top: 0;
}
.floating-error-backdrop .floating-error-container .floating-error__message {
  grid-area: 2/1/3/2;
  padding: 1.2rem;
  margin: 0;
}
.floating-error-backdrop .floating-error-container .floating-error__button {
  color: #ffffff;
  cursor: pointer;
  grid-area: 3/1/4/2;
  margin: 1.2rem auto;
  padding: 0.8rem 2.4rem;
  background-color: #a93e73;
}

.context-menu {
  position: relative;
  display: inline-flex;
}
.context-menu__toggler {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.context-menu__toggler:hover {
  background-color: #e7ecef;
}
.context-menu__toggler.--opened {
  background-color: #e7ecef;
}
.context-menu__container {
  position: absolute;
  top: 0;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.12), 0 1px 48px rgba(0, 0, 0, 0.24);
  z-index: 100;
  -webkit-animation: opac-up 0.2s linear;
  -moz-animation: opac-up 0.2s linear;
  -ms-animation: opac-up 0.2s linear;
  -o-animation: opac-up 0.2s linear;
  animation: opac-up 0.2s linear;
}
.context-menu__container .menu-item {
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid #e0e7e9;
  cursor: pointer;
  min-width: 20rem;
  max-width: 25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.context-menu__container .menu-item:hover {
  background-color: #e0e7e9;
}
.context-menu__container .menu-item:last-of-type {
  border-bottom: none;
}
.context-menu__container .menu-item__icon {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
}
.context-menu__container .menu-item__content {
  display: inline-block;
}
.context-menu__container .menu-item .wrapper {
  margin: 0;
  padding: 0;
}
.context-menu__container .menu-item .wrapper .switch-sub-container {
  padding: 0;
  min-height: 3.4rem;
}

@keyframes opac-up {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.om-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 12rem;
  height: 40px;
  cursor: pointer;
  border: none;
  background-color: #a93e73;
  color: #ffffff;
  border: 2px solid #a93e73;
  padding: 0 1rem;
  font-size: 1.4rem;
  margin: 0;
}
.om-btn:focus {
  border: 2px solid #923061;
}
.om-btn:hover {
  border: 2px solid #923061;
  background-color: #923061;
}
.om-btn__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.2rem;
}
.om-btn.--SECONDARY {
  background-color: #6f6f6f;
  border: 2px solid #6f6f6f;
  color: #ffffff;
}
.om-btn.--SECONDARY:hover {
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}
.om-btn.--GHOST {
  background-color: transparent;
  border: 1px solid #a93e73;
  color: #a93e73;
}
.om-btn.--GHOST:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.om-btn.--SIMPLE {
  background-color: transparent;
  border: 2px solid transparent;
  color: #a93e73;
}
.om-btn.--SIMPLE:hover {
  background-color: #d6d6d6;
  color: #a93e73;
  border: 2px solid transparent;
}
.om-btn.--SIMPLE:focus {
  border: 2px solid #a93e73;
}
.om-btn.--TEXT {
  background-color: transparent;
  border: 2px solid transparent;
  color: #a93e73;
  text-decoration: none;
}
.om-btn.--TEXT:hover {
  background-color: transparent;
  border: 2px solid transparent;
  text-decoration: underline;
  color: #bb4680;
}
.om-btn.--DANGER {
  background-color: #fb3350;
  border: 2px solid #fb3350;
  color: #ffffff;
}
.om-btn.--DANGER:hover {
  background-color: #fb425d;
  border: 2px solid #fb425d;
}
.om-btn.--FIELD {
  height: 35px;
  min-width: 11rem;
}
.om-btn.--SMALL {
  height: auto;
  min-height: 2.7rem;
}
.om-btn.--THICK {
  height: 70px;
}
.om-btn.--DISABLED {
  background-color: #999999 !important;
  cursor: not-allowed !important;
  border: 2px solid #999999 !important;
  color: #ffffff !important;
  text-decoration: none;
}
.om-btn.--DISABLED:hover {
  background-color: #999999 !important;
  cursor: not-allowed !important;
  text-decoration: none;
  border: 2px solid #999999 !important;
  color: #ffffff !important;
}

.om-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.om-container.--right {
  justify-content: flex-end;
}
.om-container.--left {
  justify-content: flex-start;
}

.foldable-view {
  margin: 2.4rem auto;
  background-color: #ffffff;
}
.foldable-view__header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  font-weight: 500;
}
.foldable-view__header .edit-icon {
  color: #a93e73;
}
.foldable-view__header.--EDIT:hover {
  background-color: #f5f5f5;
}
.foldable-view__container {
  border-top: 1px solid whitesmoke;
  padding: 1rem;
}
.foldable-view__closed-container {
  font-size: 1rem;
  padding: 1rem;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@-webkit-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes smrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-moz-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-ms-keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@keyframes smball1 {
  0% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #a93e73;
    -moz-box-shadow: 15px 0 0 #a93e73;
    box-shadow: 15px 0 0 #a93e73;
    margin-bottom: 5px;
  }
}
@-webkit-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes smball2 {
  0% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -10px;
    transform: translate(7.5px, 7.5px);
    -webkit-transform: translate(7.5px, 7.5px);
    -moz-transform: translate(7.5px, 7.5px);
    -ms-transform: translate(7.5px, 7.5px);
    -o-transform: translate(7.5px, 7.5px);
  }
  100% {
    -webkit-box-shadow: 15px 0 0 #fb3350;
    -moz-box-shadow: 15px 0 0 #fb3350;
    box-shadow: 15px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-SM .loader {
  -webkit-animation: smrotate 1s infinite;
  -moz-animation: smrotate 1s infinite;
  -ms-animation: smrotate 1s infinite;
  -o-animation: smrotate 1s infinite;
  animation: smrotate 1s infinite;
  height: 25px;
  width: 25px;
  margin-bottom: 1.2rem;
}
.loading-size-SM .loader:before,
.loading-size-SM .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
}
.loading-size-SM .loader:before {
  -webkit-box-shadow: 15px 0 0 #a93e73;
  -moz-box-shadow: 15px 0 0 #a93e73;
  box-shadow: 15px 0 0 #a93e73;
  margin-bottom: 5px;
  -webkit-animation: smball1 1s infinite;
  -moz-animation: smball1 1s infinite;
  -ms-animation: smball1 1s infinite;
  -o-animation: smball1 1s infinite;
  animation: smball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-SM .loader:after {
  -webkit-animation: smball2 1s infinite;
  -moz-animation: smball2 1s infinite;
  -ms-animation: smball2 1s infinite;
  -o-animation: smball2 1s infinite;
  animation: smball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 15px 0 0 #fb3350;
  -moz-box-shadow: 15px 0 0 #fb3350;
  box-shadow: 15px 0 0 #fb3350;
}

@-webkit-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes mdrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-moz-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-ms-keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@keyframes mdball1 {
  0% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #a93e73;
    -moz-box-shadow: 30px 0 0 #a93e73;
    box-shadow: 30px 0 0 #a93e73;
    margin-bottom: 10px;
  }
}
@-webkit-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes mdball2 {
  0% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -20px;
    transform: translate(15px, 15px);
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
    -ms-transform: translate(15px, 15px);
    -o-transform: translate(15px, 15px);
  }
  100% {
    -webkit-box-shadow: 30px 0 0 #fb3350;
    -moz-box-shadow: 30px 0 0 #fb3350;
    box-shadow: 30px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-MD .loader {
  -webkit-animation: mdrotate 1s infinite;
  -moz-animation: mdrotate 1s infinite;
  -ms-animation: mdrotate 1s infinite;
  -o-animation: mdrotate 1s infinite;
  animation: mdrotate 1s infinite;
  height: 50px;
  width: 50px;
  margin-bottom: 1.2rem;
}
.loading-size-MD .loader:before,
.loading-size-MD .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loading-size-MD .loader:before {
  -webkit-box-shadow: 30px 0 0 #a93e73;
  -moz-box-shadow: 30px 0 0 #a93e73;
  box-shadow: 30px 0 0 #a93e73;
  margin-bottom: 10px;
  -webkit-animation: mdball1 1s infinite;
  -moz-animation: mdball1 1s infinite;
  -ms-animation: mdball1 1s infinite;
  -o-animation: mdball1 1s infinite;
  animation: mdball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-MD .loader:after {
  -webkit-animation: mdball2 1s infinite;
  -moz-animation: mdball2 1s infinite;
  -ms-animation: mdball2 1s infinite;
  -o-animation: mdball2 1s infinite;
  animation: mdball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 30px 0 0 #fb3350;
  -moz-box-shadow: 30px 0 0 #fb3350;
  box-shadow: 30px 0 0 #fb3350;
}

@-webkit-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-moz-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-ms-keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@keyframes lgrotate {
  0% {
    transform: rotate(0deg) scale(0.8);
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
    -ms-transform: rotate(0deg) scale(0.8);
    -o-transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
    -ms-transform: rotate(360deg) scale(1.2);
    -o-transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
    -ms-transform: rotate(720deg) scale(0.8);
    -o-transform: rotate(720deg) scale(0.8);
  }
}
@-webkit-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-moz-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-ms-keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@keyframes lgball1 {
  0% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #a93e73;
    -moz-box-shadow: 0 0 0 #a93e73;
    box-shadow: 0 0 0 #a93e73;
    margin-bottom: 0;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #a93e73;
    -moz-box-shadow: 60px 0 0 #a93e73;
    box-shadow: 60px 0 0 #a93e73;
    margin-bottom: 20px;
  }
}
@-webkit-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-moz-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@-ms-keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
@keyframes lgball2 {
  0% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
  }
  50% {
    -webkit-box-shadow: 0 0 0 #fb3350;
    -moz-box-shadow: 0 0 0 #fb3350;
    box-shadow: 0 0 0 #fb3350;
    margin-top: -40px;
    transform: translate(30px, 30px);
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    -o-transform: translate(30px, 30px);
  }
  100% {
    -webkit-box-shadow: 60px 0 0 #fb3350;
    -moz-box-shadow: 60px 0 0 #fb3350;
    box-shadow: 60px 0 0 #fb3350;
    margin-top: 0;
  }
}
.loading-size-LG .loader {
  -webkit-animation: lgrotate 1s infinite;
  -moz-animation: lgrotate 1s infinite;
  -ms-animation: lgrotate 1s infinite;
  -o-animation: lgrotate 1s infinite;
  animation: lgrotate 1s infinite;
  height: 100px;
  width: 100px;
  margin-bottom: 1.2rem;
}
.loading-size-LG .loader:before,
.loading-size-LG .loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
}
.loading-size-LG .loader:before {
  -webkit-box-shadow: 60px 0 0 #a93e73;
  -moz-box-shadow: 60px 0 0 #a93e73;
  box-shadow: 60px 0 0 #a93e73;
  margin-bottom: 20px;
  -webkit-animation: lgball1 1s infinite;
  -moz-animation: lgball1 1s infinite;
  -ms-animation: lgball1 1s infinite;
  -o-animation: lgball1 1s infinite;
  animation: lgball1 1s infinite;
  background-color: #fb3350;
}
.loading-size-LG .loader:after {
  -webkit-animation: lgball2 1s infinite;
  -moz-animation: lgball2 1s infinite;
  -ms-animation: lgball2 1s infinite;
  -o-animation: lgball2 1s infinite;
  animation: lgball2 1s infinite;
  background-color: #a93e73;
  -webkit-box-shadow: 60px 0 0 #fb3350;
  -moz-box-shadow: 60px 0 0 #fb3350;
  box-shadow: 60px 0 0 #fb3350;
}

.spaced-container {
  padding: 2.4rem 0;
}

.absolute-container {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.fixed-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.transparent-bg-container {
  background-color: transparent !important;
}

.ripple-loading-container {
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  z-index: 200;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.ripple-loading-container .ripple-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ripple-loading-container .ripple-loading .ripple {
  position: absolute;
  border: 4px solid #e5e5e5;
  opacity: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  -moz-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  -ms-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  -o-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ripple-loading-container .ripple-loading .ripple:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 76px;
    height: 76px;
    opacity: 0;
  }
}

.inline-loading {
  display: inline-block;
}
.inline-loading .lds-ring {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
}
.inline-loading .lds-ring div {
  width: 1.5rem;
  display: block;
  height: 1.5rem;
  margin: 0.2rem;
  position: absolute;
  box-sizing: border-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 0.2rem solid #a93e73;
  border-color: #a93e73 transparent transparent transparent;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -ms-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.inline-loading .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.inline-loading .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.inline-loading .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.inline-loading.alternate-inline-loading .lds-ring div {
  border: 0.2rem solid #ffffff;
  border-color: #ffffff transparent transparent transparent;
}

.topline-info {
  background-color: #ffd750;
  padding: 1.2rem;
  text-align: center;
  position: relative;
  z-index: -1;
  -webkit-animation: slide-down 0.3s linear 0s 1 forwards;
  -moz-animation: slide-down 0.3s linear 0s 1 forwards;
  -ms-animation: slide-down 0.3s linear 0s 1 forwards;
  -o-animation: slide-down 0.3s linear 0s 1 forwards;
  animation: slide-down 0.3s linear 0s 1 forwards;
}
.topline-info--successful {
  background-color: #7de394;
  color: white;
  -webkit-animation: slide-down-and-up 3s linear 0s 1 forwards;
  -moz-animation: slide-down-and-up 3s linear 0s 1 forwards;
  -ms-animation: slide-down-and-up 3s linear 0s 1 forwards;
  -o-animation: slide-down-and-up 3s linear 0s 1 forwards;
  animation: slide-down-and-up 3s linear 0s 1 forwards;
}
.topline-info--system-message {
  -webkit-animation: slide-down-and-up-slow 10s linear 0s 1 forwards;
  -moz-animation: slide-down-and-up-slow 10s linear 0s 1 forwards;
  -ms-animation: slide-down-and-up-slow 10s linear 0s 1 forwards;
  -o-animation: slide-down-and-up-slow 10s linear 0s 1 forwards;
  animation: slide-down-and-up-slow 10s linear 0s 1 forwards;
}
.topline-info--system-message .title {
  font-weight: 600;
  margin-bottom: 0.8rem;
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
}
@keyframes slide-down-and-up {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
  90% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
}
@keyframes slide-down-and-up-slow {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
  }
  3.3% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
  27% {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
  }
  30.3% {
    transform: translateY(-65%);
    -webkit-transform: translateY(-65%);
    -moz-transform: translateY(-65%);
    -ms-transform: translateY(-65%);
    -o-transform: translateY(-65%);
  }
  96.6% {
    opacity: 1;
    transform: translateY(-65%);
    -webkit-transform: translateY(-65%);
    -moz-transform: translateY(-65%);
    -ms-transform: translateY(-65%);
    -o-transform: translateY(-65%);
  }
  100% {
    opacity: 0;
    transform: translateY(-200%);
    -webkit-transform: translateY(-200%);
    -moz-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    -o-transform: translateY(-200%);
  }
}

.om-ttl {
  width: 100%;
}
.om-ttl__space-before {
  padding-top: 1.2rem;
}
.om-ttl__space-after {
  padding-bottom: 1.2rem;
}
.om-ttl.--T1 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #6a6a6a;
}
.om-ttl.--T2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #525252;
}
.om-ttl.--T3 {
  font-weight: 600;
  font-size: 1.4rem;
  color: #777777;
  border-bottom: 1px solid #e0e7e9;
  margin-bottom: 2.4rem;
  padding-bottom: 0.8rem;
}
.om-ttl.--T4 {
  font-weight: 600;
  font-size: 1.2rem;
  color: #777777;
}
.om-ttl.--T4.om-ttl__space-before {
  padding-top: 0.8rem;
}
.om-ttl.--T4.om-ttl__space-after {
  padding-bottom: 0.8rem;
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: -moz-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  background: -o-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  background: -ms-radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  background: radial-gradient(rgba(150, 150, 150, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 10000;
  display: flex;
  padding: 0 2.4rem;
  justify-content: center;
  align-items: center;
}
.modal-container .modal {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px auto 50px;
  background-color: #ffffff;
}
.modal-container .modal__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #e7ecef;
  grid-area: 1/1/2/2;
  height: 50px;
}
.modal-container .modal__heading--content {
  padding: 0.8rem 1.2rem;
  font-size: 1.8rem;
  font-weight: 500;
}
.modal-container .modal__heading--close {
  height: 49px;
  padding: 0 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-container .modal__heading--close:hover {
  background-color: #e0e7e9;
}
.modal-container .modal__content {
  display: block;
  padding: 2.4rem;
  grid-area: 2/1/3/2;
  overflow-y: auto;
}
.modal-container .modal__controls {
  display: flex;
  z-index: 10;
  align-items: center;
  position: relative;
  justify-content: space-between;
  grid-area: 3/1/4/2;
}
.modal-container .modal__controls--button {
  height: 50px;
  width: 50%;
}
.modal-container .modal-allow-overflow .modal__content {
  overflow-y: unset;
}
.modal-container .modal-allow-overflow .modal__controls {
  z-index: unset;
}
.modal-container .modal-passive {
  grid-template-rows: 45px auto;
}
.modal-container .modal-XS {
  max-width: 320px;
  width: 100%;
}
.modal-container .modal-XS .modal__content {
  max-height: 80vh;
}
.modal-container .modal-SM {
  max-width: 576px;
  width: 100%;
}
.modal-container .modal-SM .modal__content {
  max-height: 80vh;
}
.modal-container .modal-MD {
  max-width: 768px;
  width: 100%;
  height: 50vh;
}
.modal-container .modal-LG {
  max-width: 992px;
  width: 100%;
  height: 80vh;
}
.modal-container .modal-LG .modal__content {
  max-height: unset;
}
@media screen and (max-width: 576px) {
  .modal-container {
    padding: 0;
  }
  .modal-container .modal-XS,
.modal-container .modal-SM,
.modal-container .modal-MD,
.modal-container .modal-LG {
    height: 100vh;
    max-width: 100%;
  }
  .modal-container .modal-XS .modal__content,
.modal-container .modal-SM .modal__content,
.modal-container .modal-MD .modal__content,
.modal-container .modal-LG .modal__content {
    max-height: unset;
  }
}

.tabulator {
  margin: 0 auto;
  max-width: 90rem;
  background-color: transparent;
}
.tabulator__head {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: auto;
}
.tabulator__head--tab {
  padding: 1.2rem;
  background-color: #e0e7e9;
  color: #171717;
  border-top: 2px solid #e0e7e9;
  cursor: pointer;
}
.tabulator__head--tab:hover {
  border-top: 2px solid #d6d6d6;
  background-color: #d6d6d6;
}
.tabulator__head--tab.active-tab {
  border-top: 2px solid #a93e73;
  background-color: #ffffff;
}
.tabulator__body--tab {
  position: relative;
  display: none;
  padding: 3.2rem 1.6rem;
  background-color: #ffffff;
}
.tabulator__body--tab.active-tab {
  display: block;
}
@media screen and (max-width: 768px) {
  .tabulator__body--tab {
    padding: 1.6rem 0.8rem;
  }
}

.om-label.--L1 {
  font-size: 1.2rem;
  color: #6a6a6a;
  margin-bottom: 0.8rem;
}
.om-label.--L2 {
  font-size: 1.2rem;
  color: #6a6a6a;
  margin-bottom: 0.8rem;
  font-style: italic;
}

.om-text.--X1 {
  font-size: 1.4rem;
}
.om-text.--X2 {
  font-size: 1.2rem;
}
.om-text.--X3 {
  font-size: 1.2rem;
  color: #a2a2a2;
}
.om-text.--X4 {
  font-size: 1.2rem;
  color: #a2a2a2;
  font-style: italic;
}
.om-text.--X5 {
  font-size: 1.2rem;
  color: #a2a2a2;
}

.dot-pulse:after {
  content: " .";
  font-family: "Open Sans";
  -webkit-animation: dots 1s steps(5, end) infinite;
  -moz-animation: dots 1s steps(5, end) infinite;
  -ms-animation: dots 1s steps(5, end) infinite;
  -o-animation: dots 1s steps(5, end) infinite;
  animation: dots 1s steps(5, end) infinite;
}
@keyframes dots {
  0%, 20% {
    color: #a93e7400;
    text-shadow: 0.5rem 0 0 #a93e7400, 1rem 0 0 #a93e7400;
  }
  40% {
    color: #a93e73;
    text-shadow: 0.5rem 0 0 #a93e7400, 1rem 0 0 #a93e7400;
  }
  60% {
    text-shadow: 0.5rem 0 0 #a93e73, 1rem 0 0 #a93e7400;
  }
  80%, 100% {
    text-shadow: 0.5rem 0 0 #a93e73, 1rem 0 0 #a93e73;
  }
}

.wrapper {
  display: block;
  margin-bottom: 1.2rem;
  position: relative;
}
.wrapper .input-label {
  position: relative;
  width: 100%;
  padding-bottom: 0.4rem;
  display: block;
  font-size: 1.4rem;
  color: #6a6a6a;
}
.wrapper .common-input {
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 1.2rem;
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid #d6d6d6;
  color: #171717;
  height: 4.1rem;
  background-color: #f0f3f5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.wrapper .common-input::placeholder {
  font-size: 1.2rem;
  color: #6a6a6a;
  font-style: italic;
}
.wrapper .input-description {
  font-size: 1.2rem;
  font-style: italic;
  color: #999999;
}

.wrapper-disabled {
  cursor: not-allowed;
}
.wrapper-disabled .input-label {
  cursor: not-allowed;
}
.wrapper-disabled .common-input {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b2b2b2;
}

.wrapper-hidden {
  display: none;
}

@media screen and (max-width: 576px) {
  .wrapper .common-input,
.wrapper .text-input,
.wrapper .select-input {
    height: 3.1rem;
  }

  .select-container .select-input {
    max-height: 3.1rem !important;
  }
  .select-container .select-input__control {
    height: 3.1rem !important;
  }
  .select-container .select-input__value-container {
    height: 3.1rem !important;
  }
  .select-container .select-input__dropdown-indicator {
    height: 3.1rem !important;
    width: 3.1rem !important;
  }

  .switch-container .switch-sub-container {
    min-height: 5.4rem !important;
  }
}
.switch-container {
  width: 100%;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.switch-container .label {
  font-size: 1.4rem;
  color: #6a6a6a;
  margin-bottom: 0.2rem;
}
.switch-container .switch-sub-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.2rem;
  padding: 0.8rem 1.2rem;
  min-height: 6.4rem;
  cursor: pointer;
}
.switch-container .switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  background-color: #d6d6d6;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* Rounded sliders */
}
.switch-container .switch .switch-input {
  position: relative;
  cursor: pointer;
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-container .switch .switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.07s;
  -moz-transition: all 0.07s;
  -ms-transition: all 0.07s;
  -o-transition: all 0.07s;
  transition: all 0.07s;
}
.switch-container .switch .switch::before {
  position: absolute;
  content: "";
  height: 1.6rem;
  width: 1.6rem;
  left: 0.2rem;
  bottom: 0.2rem;
  -webkit-transition: 0.07s;
  -moz-transition: 0.07s;
  -ms-transition: 0.07s;
  -o-transition: 0.07s;
  transition: 0.07s;
  background-color: #ffffff;
}
.switch-container .switch .switch-input:checked + .switch {
  background-color: #7de394;
}
.switch-container .switch .switch-input:checked + .switch::before {
  transform: translateX(2rem);
  -webkit-transform: translateX(2rem);
  -moz-transform: translateX(2rem);
  -ms-transform: translateX(2rem);
  -o-transform: translateX(2rem);
}
.switch-container .switch .switch-toggler {
  -webkit-border-radius: 3.4rem;
  -moz-border-radius: 3.4rem;
  border-radius: 3.4rem;
}
.switch-container .switch .switch-toggler::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.switch-container .switch-label {
  font-size: 1.4rem;
  color: #6a6a6a;
  cursor: pointer;
  margin-left: 1rem;
}

.toggle-button {
  display: grid;
  grid-template-columns: 76px auto;
  background-color: #f0f3f5;
  align-items: flex-start;
  min-height: 9rem;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.toggle-button .switch-container {
  margin-bottom: 0;
}
.toggle-button .switch-container .switch-sub-container {
  padding-top: 1.2rem;
  align-items: flex-start;
}
.toggle-button__title {
  margin-bottom: 0.8rem;
}
.toggle-button__text-container {
  padding: 1.2rem 1.2rem 1.2rem 0;
}
.toggle-button__description {
  font-size: 1.2rem;
  color: #6a6a6a;
}
.toggle-button.--no-desc {
  align-items: center;
}
.toggle-button.--no-desc .switch-container .switch-sub-container {
  padding: 1.2rem;
  align-items: center;
}
.toggle-button.--no-desc .toggle-button__title {
  margin-bottom: 0;
}
.toggle-button.toggle-button-small {
  min-height: unset;
  align-items: center;
}

.disabled-toggle {
  background-color: #d6d6d6;
  color: #b2b2b2;
  cursor: not-allowed;
}
.disabled-toggle .switch-container .switch {
  background-color: #bfbfbf;
}

.disabled-switch-wrapper {
  cursor: not-allowed;
}
.disabled-switch-wrapper .switch-sub-container {
  cursor: not-allowed;
}

.checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.4rem;
  color: #6a6a6a;
  height: 3rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-container .checkbox-input {
  position: absolute;
  opacity: 0;
  right: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container .check-mark {
  background-color: #ffffff;
  border: 2px solid #e0e7e9;
  top: 0;
  left: 0;
  margin-right: 0.8rem;
  height: 2rem;
  width: 2rem;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.checkbox-container .check-mark::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.45rem;
  top: -0.2rem;
  width: 0.6rem;
  height: 1.5rem;
  border-style: solid;
  border-width: 0 0.2rem 0.2rem 0;
  border-color: #ffffff;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.checkbox-container .checkbox-input:checked ~ .check-mark {
  background-color: #a93e73;
  border: 2px solid #a93e73;
}
.checkbox-container .checkbox-input:checked ~ .check-mark::after {
  display: block;
}
.checkbox-container .checkbox-link {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 0;
  padding-bottom: 0;
}

.wrapper-disabled {
  cursor: not-allowed;
}
.wrapper-disabled .checkbox-container {
  cursor: not-allowed;
  color: #b2b2b2;
}
.wrapper-disabled .checkbox-container .check-mark {
  background-color: #d6d6d6;
  border: 2px solid #d6d6d6;
}

.datepicker-wrapper .datepicker-internal-container {
  width: 100%;
  position: relative;
}
.datepicker-wrapper .datepicker-internal-container .datepicker-input {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding-right: 5rem;
}
.datepicker-wrapper .datepicker-internal-container .datepicker-input .clear {
  position: absolute;
  right: 0rem;
  top: 0;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7ecef;
}
.datepicker-wrapper .datepicker-internal-container .datepicker-input .clear:hover {
  color: #a93e73;
}
.datepicker-wrapper .datepicker-internal-container .datepicker-input .placeholder {
  color: #6a6a6a;
  font-size: 1.2rem;
  font-style: italic;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.datepicker-wrapper .datepicker-internal-container .date-popper {
  position: absolute;
  top: 4.8rem;
  left: 0;
  background-color: #ffffff;
  width: 28rem;
  z-index: 100;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.datepicker-wrapper .datepicker-internal-container .date-popper__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  position: relative;
  background-color: #e0e7e9;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__control--label {
  padding: 1.2rem;
  font-weight: 500;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__control--triangle {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #e0e7e9;
  position: absolute;
  top: -1rem;
  left: 2rem;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__control .svg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  cursor: pointer;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__control .svg:hover {
  background-color: #e7ecef;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year {
  position: relative;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year-list {
  position: absolute;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  max-height: 10rem;
  background-color: #ffffff;
  top: 0;
  border: 1px solid #d6d6d6;
  z-index: 1;
  left: 25%;
  right: 25%;
  width: 50%;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year-list__show-more {
  padding: 0.2rem 0.4rem;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year-list__show-more:hover {
  background-color: #d6d6d6;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year-list__item {
  width: 100%;
  padding: 0.2rem 0.4rem;
  text-align: center;
  cursor: pointer;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year-list__item.active-year {
  background-color: #a93e73;
  color: #ffffff;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year {
  cursor: pointer;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__year .year:hover {
  color: #a93e73;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__month, .datepicker-wrapper .datepicker-internal-container .date-popper__year {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__month .control, .datepicker-wrapper .datepicker-internal-container .date-popper__year .control {
  font-weight: 400;
  width: 4rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__month .control:hover, .datepicker-wrapper .datepicker-internal-container .date-popper__year .control:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data .placeholder {
  background-color: #e7ecef;
  opacity: 0.4;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data--week-day {
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data--day {
  width: 100%;
  text-align: center;
  padding: 0.4rem;
  position: relative;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data--day:hover {
  background-color: #e7ecef;
  cursor: pointer;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data .today {
  background-color: #d6d6d6;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data .selected-day {
  background-color: #a93e73;
  color: #ffffff;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data .out-of-range-date {
  color: #d6d6d6;
  cursor: not-allowed;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__data .out-of-range-date:hover {
  background-color: transparent;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__time-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  border-top: 1px solid #d6d6d6;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__time-inputs .select-container {
  margin-bottom: 0;
  position: relative;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__time-inputs .select-container .input-label {
  position: absolute;
  width: 100%;
  text-align: right;
  pointer-events: none;
  background: none !important;
  right: 5rem;
  top: 0.9rem;
  z-index: 1;
}
.datepicker-wrapper .datepicker-internal-container .date-popper__time-inputs .select-container .select-input__menu-list {
  max-height: 8rem;
}
@media screen and (max-width: 576px) {
  .datepicker-wrapper .datepicker-internal-container .datepicker-input .clear {
    height: 3rem;
    width: 3rem;
  }
}

.wrapper-disabled .datepicker-internal-container .datepicker-input {
  cursor: not-allowed;
  background-color: #d6d6d6;
}

.select-container {
  max-width: 100%;
  width: 100%;
}
.select-container .select-input {
  max-height: 4.1rem !important;
  min-height: unset !important;
  max-width: 100%;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.select-container .select-input__control {
  min-height: unset;
  max-height: unset;
  height: 4.1rem;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #d6d6d6;
  background-color: #f0f3f5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.select-container .select-input__control:hover {
  border-bottom: 1px solid #d6d6d6;
}
.select-container .select-input__value-container {
  flex-wrap: nowrap;
  min-height: unset;
  max-height: unset;
  height: 4.1rem;
}
.select-container .select-input__input {
  width: 100%;
  display: block;
}
.select-container .select-input__input input {
  width: 100% !important;
}
.select-container .select-input__menu {
  font-size: 14px;
}
.select-container .select-input__menu__option--is-focused {
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  cursor: pointer;
}
.select-container .select-input__multi-value__label, .select-container .select-input__value__label {
  background-color: #a93e73;
  color: #ffffff;
}
.select-container .select-input__multi-value__remove, .select-container .select-input__value__remove {
  background-color: #a93e73;
  color: #ffffff;
  cursor: pointer;
}
.select-container .select-input__multi-value__remove:hover, .select-container .select-input__value__remove:hover {
  background-color: #e0e7e9;
}
.select-container .select-input__indicator {
  color: #6a6a6a;
}
.select-container .select-input__indicator-separator {
  display: none;
}
.select-container .select-input__indicator:hover {
  color: #a93e73;
}
.select-container .select-input__dropdown-indicator {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 4.1rem;
  height: 4.1rem;
  color: #171717;
  border-bottom: 1px solid #d6d6d6;
  background-color: #e7ecef;
}
.select-container .select-input__menu {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.select-container .select-input__option {
  color: #171717;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 1.2rem;
  cursor: pointer;
}
.select-container .select-input__option--is-focused {
  background-color: #e0e7e9;
  color: #171717;
}
.select-container .select-input__option--is-selected {
  color: #ffffff;
  background-color: #a93e73;
}

.disabled-select-wrapper {
  cursor: not-allowed !important;
}
.disabled-select-wrapper .select-input__indicator {
  display: none;
}
.disabled-select-wrapper .select-input__indicator-separator {
  display: none;
}
.disabled-select-wrapper .select-input__control {
  background-color: #e0e7e9;
  color: white !important;
}
.disabled-select-wrapper .select-input__control__value-container__single-value {
  color: #6a6a6a;
}

.wrapper .text-area {
  border: none;
  width: 100%;
  resize: vertical;
  background-color: #f0f3f5;
  font-family: "Open Sans";
  border-bottom: 1px solid #d6d6d6;
  padding: 0.4rem 1.2rem;
  max-height: 400px;
  min-height: 120px;
}

.wrapper .text-input-unit {
  position: absolute;
  font-size: 1.2rem;
  color: #6a6a6a;
  width: 4.1rem;
  height: 4.1rem;
  top: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7ecef;
  right: 0;
  border-bottom: 1px solid #d6d6d6;
}
.wrapper .text-input-unit-without-label {
  top: 0;
}
.wrapper .clickable-unit {
  cursor: pointer;
}
.wrapper .clickable-unit:hover {
  color: #a93e73;
}
.wrapper .search-input {
  padding-left: 4.8rem;
}
.wrapper .search-icon {
  left: 0;
  right: unset;
  pointer-events: none;
  background: none !important;
}
.wrapper .input-label-with-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .input-label-with-unit .input-controls {
  display: flex;
  align-items: center;
}
.wrapper .input-label-with-unit .input-controls__size {
  margin-left: 0.4rem;
  cursor: pointer;
}
.wrapper .input-label-with-unit .input-controls__size:hover {
  color: #171717;
}
.wrapper .input-label-with-unit .input-controls__size.active-size {
  color: #171717;
  font-weight: 500;
}
@media screen and (max-width: 576px) {
  .wrapper .text-input-unit {
    height: 3.1rem;
    width: 3.1rem;
  }
}

.image-uploader-container .image-uploader {
  background-color: #a93e73;
  min-height: 4.1rem;
  padding-left: 1.2rem;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.image-uploader-container .image-uploader:hover {
  background-color: #b4427b;
}
.image-uploader-container .image-uploader__label {
  position: relative;
  width: 100%;
  padding-bottom: 0.4rem;
  display: block;
  font-size: 1.4rem;
  color: #6a6a6a;
}
.image-uploader-container .image-uploader__input {
  width: 0px;
  height: 0px;
  background-color: transparent;
  max-height: 0px;
  max-width: 0px;
}
.image-uploader-container .image-uploader__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  max-width: 60%;
}
.image-uploader-container .image-uploader__left--url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.image-uploader-container .image-uploader__right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 40%;
  width: 100%;
}
.image-uploader-container .image-uploader__right--icon {
  width: 4.1rem;
  height: 4.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.image-uploader-container .image-uploader__right--preview {
  width: 4.1rem;
  height: 4.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #171717;
}
.image-uploader-container .image-uploader__right--preview .xa-img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
}
@media screen and (max-width: 576px) {
  .image-uploader-container .image-uploader {
    min-height: 3.1rem;
  }
  .image-uploader-container .image-uploader .image-uploader__right--icon {
    width: 3.1rem;
    height: 3.1rem;
  }
  .image-uploader-container .image-uploader .image-uploader__right--preview {
    width: 3.1rem;
    height: 3.1rem;
  }
}

.color-wrapper .color-picker-container {
  display: grid;
  grid-template-columns: auto 34px;
}
.color-wrapper .color-picker-container .color-text-input {
  border: none;
}
.color-wrapper .color-picker-container .color-picker-input {
  padding: 0;
  border: none;
  margin: 0;
  width: 4.1rem;
  height: 4.1rem;
  grid-area: 1/1/2/2;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}
.color-wrapper .color-picker-container .color-picker {
  height: 100%;
  display: flex;
  width: 36px;
  justify-content: flex-end;
  align-items: center;
  grid-area: 1/2/2/3;
  position: relative;
  margin-top: 0.5rem;
}
.color-wrapper .color-picker-container .color-picker--no-margin-top {
  margin-top: -0.5rem;
}
.color-wrapper .color-picker-container .color-picker .no-color {
  height: 38px;
  content: " ";
  display: block;
  right: 0;
  width: 36px;
  pointer-events: none;
  background: none !important;
  position: absolute;
  z-index: 1;
}
.color-wrapper .color-picker-container .color-picker .no-color::after {
  display: block;
  content: " ";
  width: 100%;
  height: 2px;
  background-color: #a93e73;
  top: 18px;
  right: 0px;
  position: absolute;
  transform: rotateZ(-40deg);
  -webkit-transform: rotateZ(-40deg);
  -moz-transform: rotateZ(-40deg);
  -ms-transform: rotateZ(-40deg);
  -o-transform: rotateZ(-40deg);
}
.color-wrapper .color-picker-container input[type=color i] {
  background-color: #e7ecef;
}
@media screen and (max-width: 576px) {
  .color-wrapper .color-picker-container .color-picker {
    width: 3rem;
  }
  .color-wrapper .color-picker-container .color-picker .no-color {
    width: 3rem;
  }
  .color-wrapper .color-picker-container .color-picker-input {
    height: 3rem;
    width: 3rem;
  }
}

.long-select {
  position: relative;
}
.long-select__input {
  position: relative;
}
.long-select__input .text-input {
  padding-right: 4.8rem;
}
.long-select__input .text-input-unit {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e0e7e9;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.long-select__input .text-input-unit:hover {
  color: #a93e73;
}
.long-select__options {
  position: absolute;
  max-height: 20rem;
  top: 6.3rem;
  -webkit-animation: slide-up-from-below 0.2s linear;
  -moz-animation: slide-up-from-below 0.2s linear;
  -ms-animation: slide-up-from-below 0.2s linear;
  -o-animation: slide-up-from-below 0.2s linear;
  animation: slide-up-from-below 0.2s linear;
  right: 0;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  border-top: 1px solid #d6d6d6;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
}
.long-select__options--close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.long-select__options--close .svg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: #e0e7e9;
}
.long-select__options--new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  color: #a93e73;
  cursor: pointer;
}
.long-select__options--new:hover {
  background-color: #a93e73;
  color: #ffffff;
}
.long-select__options--is-typing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0.8rem;
}
.long-select__options--control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.long-select__options--control:hover {
  background-color: #e0e7e9;
}
.long-select__options--item {
  padding: 0.8rem 0.8rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  cursor: pointer;
}
.long-select__options--item:hover {
  background-color: #e7ecef;
}
.long-select__options--item.active-option {
  background-color: #a93e73;
  color: #ffffff;
}
@media screen and (max-width: 576px) {
  .long-select__input .text-input {
    padding-right: 4rem;
  }
  .long-select__input .text-input-unit {
    width: 3.1rem;
    height: 3.1rem;
  }
}
@keyframes slide-up-from-below {
  0% {
    z-index: 0;
    opacity: 0.6;
    top: 4rem;
  }
  60% {
    z-index: 1;
    opacity: 0.8;
    top: 9rem;
  }
  100% {
    z-index: 1;
    opacity: 1;
    top: 6.3rem;
  }
}

.loader-input {
  position: relative;
  width: 100%;
}
.loader-input__input {
  padding-right: 4.1rem;
}
.loader-input__loading-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.1rem;
  z-index: 11;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: none !important;
}
.loader-input__selected-item {
  position: absolute;
  left: 0;
  top: 2.3rem;
  height: 4.1rem;
  line-height: 2rem;
  right: 4.1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f6f7;
  border-bottom: 1px solid #d6d6d6;
  cursor: not-allowed;
  padding: 0 1.2rem;
  color: #6a6a6a;
}
.loader-input__selected-item .item-name {
  color: #171717;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.4rem;
}
.loader-input__clear {
  position: absolute;
  right: 0;
  top: 2.3rem;
  height: 4.1rem;
  width: 4.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7ecef;
  color: #171717;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}
.loader-input__clear:hover {
  color: #a93e73;
}
.loader-input__clear.--disabled {
  background-color: #d6d6d6;
  color: #777777;
  cursor: not-allowed;
}
.loader-input__clear.--disabled:hover {
  background-color: #d6d6d6;
  color: #777777;
}
.loader-input__list {
  display: block;
  position: absolute;
  border-top: 1px solid #d6d6d6;
  top: 6.3rem;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 100;
  max-height: 150px;
  overflow-y: auto;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
}
.loader-input__list--item {
  cursor: pointer;
  padding: 0.8rem 1.2rem;
}
.loader-input__list--item:hover {
  background-color: #e0e7e9;
}
.loader-input__list--item.--disabled {
  cursor: not-allowed;
  background-color: #d6d6d6;
}
.loader-input__no-data {
  position: relative;
  text-align: center;
  padding: 1.2rem 1.2rem;
  border-top: 1px solid #d6d6d6;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  top: -13px;
  margin-bottom: 0.4rem;
}
@media screen and (max-width: 576px) {
  .loader-input__input {
    padding-right: 3.1rem;
  }
  .loader-input__selected-item {
    height: 3.1rem;
    right: 3.1rem;
  }
  .loader-input__clear {
    height: 3.1rem;
    width: 3.1rem;
  }
}

.no-loader-input-label .loader-input__clear {
  top: 0;
}
.no-loader-input-label .loader-input__selected-item {
  top: 0;
}
.no-loader-input-label .loader-input__list {
  top: 4rem;
}

.multiselect-container {
  max-width: 100%;
  width: 100%;
}
.multiselect-container .select-input {
  max-height: 4.1rem !important;
  min-height: unset !important;
  max-width: 100%;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.multiselect-container .select-input__control {
  min-height: unset;
  max-height: unset;
  height: 4.1rem;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  background-color: #f3f6f7;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.multiselect-container .select-input__control:hover {
  border-bottom: 1px solid #d6d6d6;
}
.multiselect-container .select-input__value-container {
  flex-wrap: nowrap;
  height: 4.1rem;
  padding: 0 0.8rem;
}
.multiselect-container .select-input__input {
  width: 100%;
  display: block;
}
.multiselect-container .select-input__input input {
  width: 100% !important;
}
.multiselect-container .select-input__multi-value__label {
  background-color: #e0e7e9;
  color: #171717;
}
.multiselect-container .select-input__multi-value__remove {
  background-color: #e0e7e9;
  color: #171717;
  cursor: pointer;
}
.multiselect-container .select-input__multi-value__remove:hover {
  background-color: #a93e73;
  color: #ffffff !important;
}
.multiselect-container .select-input__indicator {
  color: #6a6a6a;
}
.multiselect-container .select-input__indicator-separator {
  display: none;
}
.multiselect-container .select-input__indicator:hover {
  color: #a93e73;
}
.multiselect-container .select-input__dropdown-indicator {
  color: #171717;
  background-color: #e7ecef;
  justify-content: center;
  align-items: center;
  width: 4.1rem;
  height: 4.1rem;
  border-bottom: 1px solid #d6d6d6;
}
.multiselect-container .select-input__menu {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 1.4rem;
}
.multiselect-container .select-input__menu__option--is-focused {
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  cursor: pointer;
}
.multiselect-container .select-input__menu, .multiselect-container .select-input__menu-list {
  max-height: 15rem;
}
.multiselect-container .select-input__option {
  color: #171717;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 1.2rem;
  cursor: pointer;
}
.multiselect-container .select-input__option--is-focused {
  background-color: #e0e7e9;
  color: #171717;
}
.multiselect-container .select-input__option--is-selected {
  color: #ffffff;
  background-color: #a93e73;
}
@media screen and (max-width: 576px) {
  .multiselect-container .select-input {
    max-height: 3.1rem;
  }
  .multiselect-container .select-input__control, .multiselect-container .select-input__value-container, .multiselect-container .select-input__indicators, .multiselect-container .select-input__indicator {
    height: 3.1rem !important;
  }
  .multiselect-container .select-input__indicator {
    padding: 0.6rem;
  }
  .multiselect-container .select-input__dropdown-indicator {
    height: 3.1rem;
    width: 3.1rem;
  }
}

.disabled-select-wrapper {
  cursor: not-allowed !important;
}
.disabled-select-wrapper .select-input__indicator {
  display: none;
}
.disabled-select-wrapper .select-input__indicator-separator {
  display: none;
}
.disabled-select-wrapper .select-input__control {
  background-color: #e0e7e9;
  color: white !important;
}
.disabled-select-wrapper .select-input__control__value-container__single-value {
  color: #6a6a6a;
}
.disabled-select-wrapper .select-input__multi-value__remove {
  display: none;
}

html {
  height: auto;
  min-height: 100%;
}

body {
  background-color: #e7ecef;
  background-image: url("./Resources/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  body {
    background-position: center;
  }
}

.spacing {
  width: 100%;
  height: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 0.8rem;
  background-color: #e0e7e9;
  color: #777777;
}

.error-container {
  background-color: #ffffff;
  width: 100%;
  margin: 1.2rem 0;
  color: #fb3350;
}
.error-container__oneline-text {
  text-align: center;
}
.error-container__redline {
  text-align: center;
}
.error-container__button .error-ok-button {
  cursor: pointer;
  background-color: #d6d6d6;
  padding: 0.8rem 1.6rem;
}
.error-container__button .error-ok-button:hover {
  background-color: #a93e73;
  color: #ffffff;
}

.global__space-after {
  padding-bottom: 1.6rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.warn-page-wrapper {
  padding-top: 15%;
}

.warn-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 1.2rem 2.4rem;
}
.warn-page__title {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.warn-page__title span {
  font-size: 40px;
}
.warn-page__container--text {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  margin: 10px auto;
  color: #888;
}
.warn-page__container--text span {
  font-weight: 600;
  font-size: 16px;
}
.warn-page__container--buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.warn-page__container--buttons .has-left-margin {
  margin-left: 10px;
}

@media screen and (max-width: 1000px) {
  .warn-page__container--buttons {
    flex-direction: column;
    align-items: center;
  }
  .warn-page__container--buttons .bx--btn {
    width: 100%;
    margin-top: 10px;
  }
  .warn-page__container--buttons .has-left-margin {
    margin-left: 0;
  }
}
.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.main-page .restaurant-block {
  padding-bottom: 55%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.main-page .restaurant-block:hover {
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .main-page .restaurant-block {
    padding-bottom: 70%;
  }
}
.main-page .align-right {
  background-position: center right;
}
.main-page .align-left {
  background-position: center left;
}

.main-page-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6.4rem;
  padding: 2.4rem 2.4rem;
  position: absolute;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
@media screen and (max-width: 768px) {
  .main-page-footer {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 1.2rem;
    padding: 2.4rem;
    position: relative;
  }
  .main-page-footer .align-left,
.main-page-footer .align-right {
    background-position: center;
  }
}
